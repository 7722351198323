import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [rmId, setRmId] = useState(null); // Add state for rm_id
  const navigate = useNavigate();

  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsAuthenticated(true);
          setUserRole(decodedToken.role);
          setRmId(decodedToken.rm_id || null);
        } else {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Token parsing error:", error);
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
      }
    }
  }, []);

  const login = (token, rememberMe) => {
    const decodedToken = jwtDecode(token);
    if (rememberMe) {
      localStorage.setItem("token", token);
    } else {
      sessionStorage.setItem("token", token);
    }
    setIsAuthenticated(true);
    setUserRole(decodedToken.role); // Store role on login
    // console.log(decodedToken);
    setRmId(decodedToken.rm_id || null); // Store rm_id on login
    navigate("/dashboard");
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    setRmId(null);
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/signin");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userRole, rmId, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
